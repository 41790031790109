@font-face {
    font-family: 'IBMPlexMono';
    src: url('../../public/fonts/IBMPlexMono-Regular.ttf') format('truetype'),
        url('../../public/fonts/IBMPlexMono-Medium.ttf') format('truetype'),
}

@font-face {
    font-family: 'IBMPlexSans';
    src: url('../../public/fonts/IBMPlexSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Sneak';
    src: url('../../public/fonts/Sneak-Regular.otf') format('opentype');
}

.disabled-link {
    pointer-events: none;
}